@import "exports";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
@import "fonts/export";
@import "classes/export";
@import "inputs/export";
@import "globals";
@import "spacing";
@import "utils";
@import "modals";

.loading-overlay:before {
  content: '';
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .1);
}

.body {
  width: 100vw;
  height: 100vh;
}

.root {
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 767px) {
    padding-top: 88px;
  }
}

.Toastify {
  z-index: 999999;
}
