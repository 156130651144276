@import "../../../styles/exports";

.footer {
  margin-top: auto;
  padding: spacing(11) 0;
  background-color: $bg-secondary;
}

.topFooterWrapper {
  padding-bottom: spacing(6);
}

.topFooterInner {
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 992px) {
    justify-content: space-between;
  }

  @media (max-width: 991px) {
    justify-content: center;
  }
}

.lowFooter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: $dark-gray-text-color;
}

.lowFooterCopyright {
  font-size: $font-size-14;
}
