@import "variables/spacing";
@import "functions";

/* overriding spacers */
$spacers: (
  1: spacing(1),
  2: spacing(2),
  3: spacing(3),
  4: spacing(4),
  5: spacing(5),
);

@import "~bootstrap";
