@import "src/styles/exports";

.wrapper {
  position: absolute;
  top: 0;
  left: 100%;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: $light-gray-color;
  transition: left, top;
  transition-duration: .5s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formsWrapper {
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding: 32px 25px;
  .goBackButton {
    position: absolute;
    top: 35px;
    left: 25px;
  }
}

.profileTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}

.openMenu {
  left: 0;
  :root {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
  }
}

.header {
  padding: 26px 25px;
  width: 100%;
  height: 78px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.body {
  position: relative;
  width: 100%;
  height: 100%;
}

.auth {
  margin-top: 40px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.footer {
  width: 100%;
  padding: 18px 54px;
  background-color: $color-main;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user {
  height: 72px;
  width: 100%;
  background-color: $bg-primary;
  display: flex;
  justify-content: space-between;
  padding: 25px;
  align-items: center;
}

.infoWrapper {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: white;
}

.avatarWrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
}
