/* common */

.step-title {
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  color: $color-main;

  @include media-down(xs) {
    font-size: 20px;
    line-height: 23px;
    text-align: center;
  }
}

/* size */

.mw-170 {
  width: 100%;
  max-width: 170px;
}

.mw-240 {
  width: 100%;
  max-width: 240px;
}

.mw-270 {
  width: 100%;
  max-width: 270px;
}

.mw-370 {
  width: 100%;
  max-width: 370px;
}

/* colors */

@each $name, $color in $colors {
  .#{$name} {
    color: $color;
  }
}

/* font */

.fs-14 {
  font-size: $font-size-14;
}

.font-weight-500 {
  font-weight: 500;
}

/* controls */

.control-label {
  text-transform: capitalize;
  font-size: $font-size-14;
  font-weight: 500;
  margin-bottom: spacing(2);
}

$inputHalfWidth: 47%;

.control-w-half {
  width: $inputHalfWidth;

  @include media-down(xs) {
    width: 100%;
  }
}

.control-flex-half {
  flex: 0 1 $inputHalfWidth;
  min-width: 200px;
}

.xs-down-flex-column {
  @include media-down(xs) {
    flex-direction: column;
  }
}

/* buttons */

.btn-medium-height {
  padding: 13px 0;
}

/* page */

.page-default-width {
  width: 100%;
  max-width: 820px;
}

.page-default-padding {
  padding: 80px 25px 100px;

  @include media-down(xs) {
    padding-top: 32px;
    padding-bottom: 56px;
  }
}

.page-title {
  text-align: center;
  font-weight: 600;

  @include media-down(xs) {
    font-size: 24px;
    line-height: 28px;
  }
}

.page-description {
  color: $dark-gray-color;
  text-align: center;

  @include media-down(xs) {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
}
