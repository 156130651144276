@import "../../../../styles/exports";

.blockWithInput {
  .error {
    margin-top: spacing(2);
    color: $danger-color;
  }

  .label {
    text-transform: capitalize;
    font-size: $font-size-14;
    font-weight: 500;
    margin-bottom: spacing(2);

    &.errorLabel {
      color: $danger-color !important;
    }

    &.white {
      color: $white-text-color;
    }

    &.black {
      color: $main-text-color;
    }
  }

  .input {
    background-color: $bg-tertiary;
    padding: spacing(3);
    border: 1px solid $middle-gray-color;
    border-radius: 5px;

    &.errorInput {
      border: 1px solid $danger-color;
      color: $danger-color;
    }

    &.small {
      max-width: 92px;
      width: 100%;
    }

    &.numberInput {
      padding: spacing(1);
      max-width: 40px;
      height: 48px;
      text-align: center;
      width: 100%;
      margin: 0 spacing(2) 0 spacing(2);
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}
