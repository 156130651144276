@import "functions";
@import "variables/breakpoints";

@mixin mx($n) {
  margin-left: spacing($n);
  margin-right: spacing($n);
}

@mixin my($n) {
  margin-top: spacing($n);
  margin-bottom: spacing($n);
}

@mixin px($n) {
  padding-left: spacing($n);
  padding-right: spacing($n);
}

@mixin py($n) {
  padding-top: spacing($n);
  padding-bottom: spacing($n);
}

@mixin responsive-ratio($x, $y) {
  $padding: unquote(( $y / $x ) * 100 + '%');

  padding-top: $padding;
}

/* media queries */

@mixin media-up($size) {
  @media (min-width: map_get($breakpoints-up, $size)) {
    @content;
  }
}

@mixin media-down($size) {
  @media (max-width: map_get($breakpoints-down, $size)) {
    @content;
  }
}

/* spacing classes */

@mixin spacing-classes($prefix: '') {
  @for $i from 0 through 20 {
    .#{$prefix}p-#{$i} {
      padding: spacing($i);
    }

    .#{$prefix}px-#{$i} {
      @include px($i);
    }

    .#{$prefix}py-#{$i} {
      @include py($i);
    }

    .#{$prefix}pt-#{$i} {
      padding-top: spacing($i);
    }

    .#{$prefix}pb-#{$i} {
      padding-bottom: spacing($i);
    }

    .#{$prefix}pl-#{$i} {
      padding-left: spacing($i);
    }

    .#{$prefix}pr-#{$i} {
      padding-right: spacing($i);
    }

    .#{$prefix}m-#{$i} {
      margin: spacing($i);
    }

    .#{$prefix}mx-#{$i} {
      @include mx($i);
    }

    .#{$prefix}my-#{$i} {
      @include my($i);
    }

    .#{$prefix}mt-#{$i} {
      margin-top: spacing($i);
    }

    .#{$prefix}mb-#{$i} {
      margin-bottom: spacing($i);
    }

    .#{$prefix}ml-#{$i} {
      margin-left: spacing($i);
    }

    .#{$prefix}mr-#{$i} {
      margin-right: spacing($i);
    }
  }
}
