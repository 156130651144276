.loading {
  display: none;
}

.loading-overlay {
  .loading {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .3);
  }
}

@keyframes ldio-syjkdu0zrip {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ldio-syjkdu0zrip div {
  position: absolute;
  animation: ldio-syjkdu0zrip 1s linear infinite;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 #0086cd;
  transform-origin: 80px 82px;
}

.loadingio-spinner-eclipse-k9po4rx7kf {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.ldio-syjkdu0zrip {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.ldio-syjkdu0zrip div {
  box-sizing: content-box;
}
